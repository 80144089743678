<template lang="html">
  <div class="v-home-mensajes p-2">
    <h3 class="text-left">Buzón de entrada - Contacto</h3>
    <div class="d-flex flex-wrap mt-4">
      <Toolbar class="p-mb-4 col-12">
        <template #left>
          <Button
            label="Borrar"
            icon="pi pi-trash"
            class="p-button-danger ml-2"
            :disabled="!selectedMensajes || !selectedMensajes.length"
            @click="confirmDeleteMensajes"
          />
          <Button
            label="Marcar no leído"
            icon="pi pi-bookmark"
            class="p-button ml-2"
            :disabled="!selectedMensajes || !selectedMensajes.length"
            @click="marcarNoLeidos"
          />
        </template>
        <template #right> </template>
      </Toolbar>
      <DataTable
        :value="mensajes"
        :paginator="true"
        class="p-datatable-customers"
        :rows="10"
        :resizableColumns="true"
        columnResizeMode="fit"
        dataKey="xmensajeid"
        :rowHover="true"
        :selection.sync="selectedMensajes"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">
            Lista de Mensajes
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global']"
                placeholder="Búsqueda global"
              />
            </span>
          </div>
        </template>
        <template #empty> No mensajes found. </template>
        <template #loading> Loading mensajes data. Please wait. </template>
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="id" header="Id" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Id</span>
            <span :class="{'fw-bold':!slotProps.data.xleido}">{{ slotProps.data.xmensajeid }}</span>
            
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xmensajeid']"
              class="p-column-filter"
              placeholder="ID"
              style="width: 3em !important"
            />
          </template>
        </Column>

        <Column
          header="Nombre"
          :sortable="true"
          sortField="xnombre"
          filterField="xnombre"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Nombre</span>
            <span :class="{'fw-bold':!slotProps.data.xleido}">{{ slotProps.data.xnombre }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xnombre']"
              class="p-column-filter"
              placeholder="Nombre"
            />
          </template>
        </Column>
        <!--APELLIDOS-->
        <Column
          header="Apellidos"
          :sortable="true"
          sortField="xapellidos"
          filterField="xapellidos"
          filterMatchMode="in"
        >
          <template #body="slotProps">
            <span class="p-column-title">Apellidos</span>
            <span :class="{'fw-bold':!slotProps.data.xleido}">{{ slotProps.data.xapellidos }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xapellidos']"
              class="p-column-filter"
              placeholder="Apellidos"
            />
          </template>
        </Column>

        <!--EMAIL-->
        <Column
          header="Email"
          :sortable="true"
          sortField="xemail"
          filterField="xemail"
        >
          <template #body="slotProps">
            <span class="p-column-title">Email</span>
            <span :class="{'fw-bold':!slotProps.data.xleido}">{{ slotProps.data.xemail }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xemail']"
              class="p-column-filter"
              placeholder="Email"
            />
          </template>
        </Column>
        <!--TELEFONO-->
        <Column
          header="Teléfono"
          :sortable="true"
          sortField="xtelefono"
          filterField="xtelefono"
        >
          <template #body="slotProps">
            <span class="p-column-title">Teléfono</span>
            <span :class="{'fw-bold':!slotProps.data.xleido}">{{ slotProps.data.xtelefono }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xtelefono']"
              class="p-column-filter"
              placeholder="Teléfono"
            />
          </template>
        </Column>
        <!--FECHA-->
        <Column
          field="xfecha_recibido"
          header="Fecha"
          sortField="xfecha_recibido"
          :sortable="true"
        >
          <template #body="slotProps">
            <span class="p-column-title">Fecha</span>
            <span :class="{'fw-bold':!slotProps.data.xleido}">{{ slotProps.data.xfecha_recibido.split("T")[0] }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xfecha_recibido']"
              class="p-column-filter"
              placeholder="Empresa"
            />
          </template>
        </Column>
        <!--Empresa-->
        <Column
          header="Empresa"
          :sortable="true"
          sortField="xcompany"
          filterField="xcompany"
        >
          <template #body="slotProps">
            <span class="p-column-title">Empresa</span>
            <span :class="{'fw-bold':!slotProps.data.xleido}">{{ slotProps.data.xcompany }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xcompany']"
              class="p-column-filter"
              placeholder="Empresa"
            />
          </template>
        </Column>

        <Column
          headerStyle="width: 8rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <Button
              type="button"
              icon="pi pi-eye"
              class="p-button-secondary"
              style="
                background-color: rgb(109, 207, 195) !important;
                border: 1px solid rgb(109, 207, 195) !important;
              "
              @click="editMensaje(slotProps.data)"
            ></Button>
            <Button
              type="button"
              icon="pi pi-trash"
              class="p-button-danger ml-2"
              @click="confirmDeleteMensaje(slotProps.data)"
            ></Button>
          </template>
        </Column>
      </DataTable>

      <Dialog
        :visible.sync="deleteMensajesDialog"
        :style="{ width: '450px' }"
        header="Confirmar"
        :modal="true"
        :baseZIndex="9002"
      >
        <div class="d-flex flex-column">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> ¿Seguro que quieres borrar estos mensajes? </span>
          <ul class="text-left ml-auto mr-auto">
            <li v-for="mensaje in selectedMensajes" :key="mensaje.xmensajeid">
              Id: {{ mensaje.xmensajeid }}
            </li>
          </ul>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text text-white"
            @click="deleteMensajesDialog = false"
          />
          <Button
            label="Sí"
            icon="pi pi-check"
            class="p-button-danger"
            @click="deleteMensajes"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="mensajeDialog"
        header="Mensaje Detalle"
        :modal="true"
        class="p-fluid col-11 col-lg-8"
        :baseZIndex="9002"
      >
        <div class="d-flex flex-wrap text-left text-xl p-5">
          <p class="col-6"><span>Nombre: </span>{{ mensaje.xnombre }}</p>
          <p class="col-6"><span>Apellido: </span>{{ mensaje.xapellidos }}</p>
          <p class="col-6">
            <a :href="'tel:' + mensaje.xtelefono"
              ><span>Teléfono: </span>{{ mensaje.xtelefono }}</a
            >
          </p>
          <p class="col-6">
            <a :href="'mailto:' + mensaje.xemail"
              ><span>Email: </span>{{ mensaje.xemail }}</a
            >
          </p>
          <p class="col-12 col-md-7">
            <span>Sector: </span>{{ mensaje.xsector }}
            <br />
            <span>Empresa: </span>{{ mensaje.xcompany }}
            <br />
            <span>Pais: </span>{{ mensaje.xpais }}
            <br />
            <span>Puesto: </span>{{ mensaje.xpuesto }}
          </p>
          <p v-if="mensaje.xfecha_recibido" class="col-12 col-md-5">
            <span>Fecha: </span>{{ mensaje.xfecha_recibido.split("T")[0] }}
          </p>
          <p class="col-12">{{ mensaje.xmensaje }}</p>
        </div>

        <template #footer>
          <Button
            type="button"
            label="Eliminar"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteMensaje(mensaje)"
          ></Button>
          <Button
            label="Cerrar"
            icon="pi pi-times"
            class="p-button"
            @click="hideDialog"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script lang="js">
import API_URL from"../../backend.js"
import BuzonContactoService from '../../services/buzonContacto.service'
import ViewService from "../../services/view.service.js"
  export default  {
    name: 'v-home-mensajes',
    props: [],
    mounted () {
      this.cargarMensajes();
      this.icons = import("../../assets/icons.js").then((icons)=>{
        this.icons = icons.default
      })
    },
    data () {
      return {
        loader:null,
        mensajes:[],
        selectedMensajes:[],
        filters: {},
        mensaje:{},
        deleteMensajesDialog:false,
        mensajeDialog:false,
        submitted:false,
        counterDanger: false,
        filteredIcons:null,
        icons:null
      }
    },
    methods: {
      cargarMensajes(){
        ViewService.getDatosWeb().then((response)=>{
          const logo = response.data[0].xlogo;
          this.loader = this.$vs.loading({
          type: "square",
          color: "#f8b505",
        });
        setTimeout(() => {
          let loaderDIV = document.getElementsByClassName(
            "vs-loading__load__animation"
          )[0];
          loaderDIV.innerHTML = "";
          const imgLogo = document.createElement("img");
          imgLogo.src = API_URL+logo;
          imgLogo.style = "width:200px; z-index: 99999999; border-radius:10px; transition: width 0.4s ease-in-out";
          
          loaderDIV.addEventListener("mouseover", (e) => {
            e.target.style.width="300px";
          })
          loaderDIV.addEventListener("mouseout", (e) => {
            e.target.style.width="200px";
          })
          loaderDIV.appendChild(imgLogo);
        }, 5);
        });
        BuzonContactoService.getTodos().then((response) => {
          this.mensajes = response.data
          this.mensajes.forEach((mensaje) => {
            mensaje.ximg= API_URL+mensaje.ximg
            mensaje.xvisible = mensaje.xvisible == 1 ? true : false
          })
          this.loader.close()
        }).catch((err) => {
          console.log(err)
          this.loader.close()
        })
      },
      filterVisibles(value, filter){
        if(filter === null ){
        return false;
        }else return true
      },
      filterDate(value, filter) {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }
        if (value === undefined || value === null) { return false; }
        return value === this.formatDate(filter);
      },
      confirmDeleteMensaje(mensaje) {
        this.selectedMensajes = [mensaje]
        this.deleteMensajesDialog = true;
      },
      confirmDeleteMensajes() {
        this.deleteMensajesDialog = true;
      },
      deleteMensajes(){
        let promesasDelete=[];
        for(let i = 0; i<this.selectedMensajes.length; i++) {
          promesasDelete.push(BuzonContactoService.borrarMensaje(this.selectedMensajes[i].xmensajeid))
        }
        Promise.all(promesasDelete).then((res) => {
          res.forEach((response) => {
            if(response.data){
              this.$toast.add({
                severity: response.data.success ? 'info' : 'error',
                summary: 'Borrado',
                detail: response.data.description,
                life: response.data.success ? 4000 : null
              })
            }
          })
          this.cargarMensajes();
          this.deleteMensajesDialog = false;
          this.mensajeDialog = false;
        }).catch((error) => {
          console.error(error);
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail:error
          })
        })
      },
      editMensaje(mensaje) {
        this.mensaje = {...mensaje};
        this.mensajeDialog = true;
        if(!mensaje.xleido){
          BuzonContactoService.setLeido(mensaje.xmensajeid).then(()=>this.cargarMensajes()).catch((error) => {
            console.error(error);
          })
        }
      },
      hideDialog() {
        this.mensajeDialog = false;
        this.submitted = false;
        this.mensaje = {}
      },
      marcarNoLeidos(){
        const promesasNoLeido=[];
        for(let i = 0; i<this.selectedMensajes.length; i++) {
          promesasNoLeido.push(BuzonContactoService.setNoLeido(this.selectedMensajes[i].xmensajeid))
        }
        Promise.all(promesasNoLeido).then(()=>{
          this.cargarMensajes()
        }).catch((error) =>{console.error(error)})
      }
    },
    computed: {

    }
}
</script>

<style lang="scss">
.v-buzon-contacto {
}
.p-datatable .p-column-resizer-helper {
  background: #f8b505 !important;
}
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #ffffff !important;
  background: #d32f2f !important;
  border: 1px solid #d32f2f !important;
}
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.p-datatable .p-sortable-column {
  width: auto;
}
.p-column-filter {
  width: 100%;
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #f8b3057a !important;
  outline: 0 none;
}
@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
.p-paginator .p-paginator-current {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #000 !important;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #f8b505 !important;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  //background: #f8b30535!important;
  background: #f8b505 !important;
  border-color: #f8b505 !important;
  color: #ffffff;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577 !important;
  border-color: #f8b505 !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #f8b505 !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577 !important;
  border-color: #f8b505 !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #f8b30535 !important;
  color: #000 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30575 !important;
  border-color: #f8b505 !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #f8b505 !important;
  background: #f8b505 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #d4a52d;
  background: #d4a52d;
  color: #ffffff !important;
}
</style>
