import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';


class BuzonContactoService {
    getTodos(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/buzonContacto/todos',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    actualizarMensaje(mensaje){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/buzonContacto/update',
            data:mensaje,
            headers: authHeader()
        }
        console.log(axiosRequest);
        return axios(axiosRequest);
    }    
    borrarMensaje(mensajeId){
        const axiosRequest = {
            method: 'delete',
            url: API_URL+'/admin/buzonContacto/borrar/'+mensajeId,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    setLeido(mensajeId){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/buzonContacto/marcarLeido/'+mensajeId,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }setNoLeido(mensajeId){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/buzonContacto/marcarNoLeido/'+mensajeId,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }

}
export default new BuzonContactoService();